<template>
  <div class="flex gap-2.5 md:gap-5 md:items-center">
    <img
      src="@/assets/icons/check.svg"
      class="w-3 md:w-5 h-3 md:h-5 mt-1.5 md:mt-0"
      v-if="color === 'dark-green'"
    />
    <img
      src="@/assets/icons/check-light-green.svg"
      class="w-3 md:w-5 h-3 md:h-5 mt-1.5 md:mt-0"
      v-if="color === 'light-green'"
    />

    <span
      class="text-sm md:text-base leading-relaxed md:leading-[24px] text-primary-gray"
    >
      <slot />
    </span>
  </div>
</template>
<script setup lang="ts">
import { computed, defineProps } from "vue";

const props = defineProps(["color"]);
const color = computed(() => {
  return props?.color || "dark-green";
});
</script>
