<template>
  <div class="h-full w-full z-50 sticky top-0">
    <div
      class="flex items-center w-full px-7 py-1 transition-all delay-200"
      :class="{ 'bg-secondary-gray': !isActive, 'bg-transparent': isActive }"
    >
      <a href="#" @click="isActive = false">
        <img src="@/assets/logo-dark.svg" class="w-20 h-auto"
      /></a>
      <img
        src="@/assets/icons/menu.svg"
        class="ml-auto w-8 h-8"
        @click="isActive = !isActive"
      />
    </div>
  </div>
  <Transition name="bounce">
    <div
      class="h-screen w-screen fixed top-0 left-0 right-0 bottom-0 bg-[url(@/assets/bg-menu.webp)] bg-contain z-10 flex flex-col pt-32"
      v-if="isActive"
    >
      <div
        class="flex items-center gap-8 h-full flex-col text-white text-lg font-medium w-full"
      >
        <a @click="isActive = false" href="#family-space">Family Space</a>
        <div class="h-0.5 w-16 bg-light-green mx-auto" />
        <a @click="isActive = false" href="#commercial">Commerical</a>
        <div class="h-0.5 w-16 bg-light-green mx-auto" />
        <a @click="isActive = false" href="#putting-greens">Putting Greens</a>
        <div class="h-0.5 w-16 bg-light-green mx-auto" />
        <a @click="isActive = false" href="#happy-pets">Happy Pets</a>
        <div class="h-0.5 w-16 bg-light-green mx-auto" />

        <a @click="isActive = false" href="#lighting">Lighting</a>
        <div class="text-center text-xs leading-6 mt-auto mb-20">
          Property of 11x12 Solutions Inc. <br />All rights reserved.
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { getCurrentPath } from "@/router/local-router";
import { onUnmounted, ref, watch } from "vue";

const currentPath = ref(getCurrentPath());
const updatePath = () => {
  currentPath.value = getCurrentPath();
};
onUnmounted(() => {
  window.addEventListener("pathchange", updatePath);
});
const isActive = ref(false);

const disableScroll = () => {
  console.log("Disabling scroll");
  document.body.style.overflow = "hidden";
  document.body.style.position = "fixed";
  document.body.style.width = "100%";
};

const enableScroll = () => {
  document.body.style.overflow = "";
  document.body.style.position = "";
  document.body.style.width = "";
};
watch(isActive, () => {
  if (isActive.value) {
    disableScroll();
  } else enableScroll();
});
</script>
<style>
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
</style>
