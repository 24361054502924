<template>
  <div class="flex flex-col gap-6 pt-8 max-w-full overflow-hidden">
    <div class="mx-auto px-8 w-full">
      <div class="font-georgia text-[22px] font-bold text-center text-green">
        Choose the highest quality turf for your yard!
      </div>
    </div>
    <div class="w-full ml-8 flex max-w-full relative">
      <div class="flex flex-col gap-2.5 md:gap-6 w-[186px] relative z-20">
        <checked-bullet
          v-for="bullet in bulletPoints"
          :key="bullet"
          color="light-green"
        >
          {{ bullet }}
        </checked-bullet>
      </div>
      <div class="flex items-center justify-center">
        <img
          src="@/assets/home/grass-roll-mobile.webp"
          class="w-60 h-[429px] absolute right-0 -z-10"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import CheckedBullet from "@/components/common/checked-bullet.vue";

const bulletPoints = [
  "15 Year Manufacturer's Warranty",
  "100% made in North America",
  "Workmanship Warranty",
  "ProFlow™ Backing",
  "Diamond Shaped Blades",
  "Cool Fiber Technology",
  "Highest Quality Resins From Dow-Dupont",
];
</script>
