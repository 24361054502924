import Commerial from "@/pages/Commerial.vue";
import FamilySpace from "@/pages/FamilySpace.vue";
import HappyPets from "@/pages/HappyPets.vue";
import Home from "@/pages/HomePage.vue";
import Lighting from "@/pages/Lighting.vue";
import NotFound from "@/pages/NotFound.vue";
import PuttingGreens from "@/pages/PuttingGreens.vue";
import Thankyou from "@/pages/ThankYou.vue";
import { DefineComponent } from "vue";
interface Route {
  path: string;
  component: DefineComponent;
  template?: string;
}
const routes: Record<string, Route> = {
  "": { path: "", component: Home as DefineComponent },
  "family-space": {
    path: "family-space",
    component: FamilySpace as DefineComponent,
  },
  commercial: {
    path: "commercial",
    component: Commerial as DefineComponent,
  },
  "happy-pets": {
    path: "happy-pets",
    component: HappyPets as DefineComponent,
  },
  "putting-greens": {
    path: "putting-greens",
    component: PuttingGreens as DefineComponent,
    template: "default",
  },
  lighting: {
    path: "lighting",
    component: Lighting as DefineComponent,
    template: "dark",
  },
  thankyou: {
    path: "thankyou",
    component: Thankyou as DefineComponent,
    template: "basic",
  },
  "404": { path: "404", component: NotFound as DefineComponent },
};
export default routes;
