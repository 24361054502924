<template>
  <NavFooterLg v-if="!isMobile" />
  <NavFooterSm v-else />
</template>
<script setup lang="ts">
import NavFooterSm from "./footer-sm.vue";
import NavFooterLg from "./footer-lg.vue";
import { onMounted, onUnmounted, ref } from "vue";

const isMobile = ref(false);

const checkDeviceType = () => {
  isMobile.value = window.matchMedia("(max-width: 768px)").matches;
};

onMounted(() => {
  checkDeviceType();
  window.addEventListener("resize", checkDeviceType);
});

onUnmounted(() => {
  window.removeEventListener("resize", checkDeviceType);
});
</script>
