<template>
  <input
    v-bind="$attrs"
    class="border border-solid border-[#5e9822] py-3 px-5 text-sm bg-transparent outline-none"
    :class="{
      'text-white': mode === 'dark',
      'text-[#333333]': mode === 'normal',
    }"
  />
</template>
<script setup lang="ts">
import { computed, defineProps } from "vue";

const props = defineProps(["mode"]);
const mode = computed(() => {
  return props.mode || "normal";
});
</script>
