<template>
  <div
    class="max-w-full w-full bg-primary-gray text-white flex justify-center items-start gap-28 py-7 px-4"
  >
    <img src="@/assets/logo-dark.svg" class="w-32" />
    <div class="flex flex-col gap-5">
      <NavItem v-for="item in footerItems.services" :key="item.title">
        <a :href="item.link"> {{ item.title }}</a>
      </NavItem>
    </div>

    <div class="flex flex-col gap-5 items-start">
      <div v-for="item in footerItems.locations" :key="item">
        {{ item }}
      </div>
    </div>
    <div class="flex flex-col gap-5 items-start max-w-[200px]">
      <div v-for="item in footerItems.contact" :key="item">
        {{ item }}
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import NavItem from "./nav-item.vue";

const footerItems = {
  services: [
    { title: "Family Space", link: "#family-space" },
    { title: "Commercial", link: "#commercial" },
    { title: "Putting Greens", link: "#putting-greens" },
    { title: "Happy Pets", link: "#happy-pets" },
    { title: "Landscape Lightings", link: "#lighting" },
  ],
  locations: ["Hamilton", "Burlington", "St. Catharines", "Niagra"],
  contact: [
    "info@perfectgreen.ca",
    "+1 (905) 674-5983",
    "",
  ],
};
</script>
