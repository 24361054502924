<template>
  <div
    class="h-auto w-4/5 bg-[#f5faf0] px-5 md:px-8 py-8 md:py-10 md:shadow-[8px_24px_34px_0_#a5a3a333] flex flex-col gap-5 md:gap-0"
    :class="{
      'md:h-[642px] md:w-60 lg:w-72': size === 'md',
      'md:h-[520px] md:w-64 lg:w-80 xl:w-96': size === 'sm',
      'md:h-[428px] md:w-80 lg:w-96': size === 'xs',
    }"
  >
    <div
      class="font-bold font-georgian text-lg md:text-2xl leading-normal text-green md:max-w-[218px] mb-7"
    >
      <slot name="title"></slot>
    </div>
    <div class="text-2xl leading-normal text-primary-gray md:mb-4">
      <slot name="price"></slot>
      <span class="text-xs leading-none"> sq/ft</span>
    </div>
    <div
      class="text-sm md:leading-normal leading-loose lg:leading-loose text-primary-gray"
    >
      <slot></slot>
    </div>

    <div class="grid grid-cols-2 mt-auto">
      <div class="flex flex-col gap-2">
        <div
          class="text-xs md:text-primary-gray uppercase text-light-green font-bold md:font-normal"
        >
          Pile height:
        </div>
        <div class="text-2xl text-green"><slot name="height" /></div>
      </div>

      <div class="flex flex-col gap-2">
        <div
          class="text-xs md:text-primary-gray uppercase text-light-green font-bold md:font-normal"
        >
          Face Weight:
        </div>
        <div class="text-2xl text-green"><slot name="weight" /></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps } from "vue";

const props = defineProps(["size"]);
const size = computed(() => {
  return props?.size || "md";
});
</script>
