<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="flex flex-col mx-auto md:gap-y-32 max-w-full overflow-x-hidden">
    <!-- HERO -->
    <div
      class="flex flex-col md:flex-row max-w-full md:max-w-[95%] lg:max-w-[1120px] mx-auto mt-10 md:mt-20 w-full md:items-center px-7 md:px-0"
    >
      <img src="@/assets/lighting/hero.webp" class="md:hidden" />

      <div class="max-w-[360px] flex flex-col gap-6 mt-8 md:mt-0">
        <div
          class="font-bold text-[32px] md:text-[52px] text-[#f5faf0] leading-tight font-georgian"
        >
          Perfect Landscape Lighting
        </div>
      </div>
      <div class="ml-auto w-full max-w-[670px] hidden md:block">
        <img src="@/assets/lighting/hero.webp" />
      </div>
    </div>

    <!-- SHOW OFF -->
    <div
      class="flex w-web-panel md:max-w-[95%] mx-auto max-w-full px-7 md:px-0"
    >
      <div class="max-w-full md:max-w-[420px] flex flex-col gap-6">
        <img
          src="@/assets/lighting/bulb.webp"
          class="h-full -mt-32 md:hidden"
        />
        <div
          class="text-[#f5faf0] md:text-3xl font-bold font-georgian text-[22px]"
        >
          Show off your stunning property
        </div>
        <div class="text-sm leading-loose">
          Your outside living space is one of the loveliest and most distinctive
          features of your home. And yet it’s probably hidden once the sun goes
          down — diminishing your enjoyment of the view and limiting the
          usability of your yard after dark. <br /><br />At Perfect Green, we
          will orchestrate the outdoor lighting fixtures to set the mood you
          want — whether it’s soft and romantic or cheerfully lit. We can even
          add a touch of color with warm washes of golden light, adding vibrancy
          and charm. If your landscaping could talk, it would thank you for
          making it look so terrific.
        </div>
      </div>
      <div class="w-[380px] h-[522px] hidden md:block">
        <img src="@/assets/lighting/bulb.webp" class="h-full -mt-28" />
      </div>
    </div>

    <!-- BULLET POINTS -->
    <div
      class="flex flex-col w-web-panel md:max-w-[95%] mx-auto gap-8 md:gap-10 max-w-full mt-20 md:mt-0"
    >
      <img src="@/assets/lighting/standing-light.webp" class="md:hidden" />
      <div
        class="mx-8 md:mx-auto text-[22px] md:text-[32px] font-georgian text-[#f5faf0] leading-normal text-center font-bold max-w-[580px]"
      >
        Our professional landscape lighting is tailored for:
      </div>
      <div class="w-full grid grid-cols-2 gap-10 mx-8 md:mx-0">
        <div class="w-full hidden md:block">
          <img src="@/assets/lighting/standing-light.webp" />
        </div>
        <div
          class="flex flex-col gap-7 justify-center col-span-2 md:col-span-1"
        >
          <div class="flex gap-5" v-for="point in points" :key="point">
            <img src="@/assets/icons/check-white.svg" class="w-5 h-5" />
            <span class="leading-tight text-lg">{{ point }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- STAR  -->
    <div
      class="flex flex-col md:flex-row w-web-panel md:max-w-[95%] max-w-full mx-auto mb-20 md:py-10 px-8 md:px-0 items-center"
    >
      <img src="@/assets/lighting/star.webp" class="w-full md:hidden" />
      <div class="max-w-[420px] flex flex-col gap-6">
        <div
          class="text-[#f5faf0] text-[22px] md:text-3xl font-bold font-georgian"
        >
          Confidence and Quality
        </div>
        <div class="text-sm leading-loose">
          Our landscape lighting systems utilise the highest quality fixtures in
          the industry.
          <br />
          <br />
          The solid copper and brass path lights and spotlights that patina over
          time with a beautiful verdigris finish, blending seamlessly into the
          existing landscape.
        </div>
      </div>
      <div class="hidden md:block">
        <img src="@/assets/lighting/star.webp" class="w-[322px]" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const points = [
  "Artificial Grass",
  "Putting Greens",
  "Trees",
  "Gardens",
  "Fountains",
  "Pathways",
  "Statues",
  "Stairs",
  "Gazebos",
];
</script>
