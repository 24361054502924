<template>
  <div class="w-full max-w-[1440px] mx-auto">
    <div
      class="w-full text-center text-2xl font-bold font-georgian text-green mb-6"
    >
      Everything you need
    </div>
    <div class="grid grid-cols-2 gap-0.5">
      <a href="#family-space">
        <div
          class="bg-[url(@/assets/home/bg-family-space.webp)] aspect-[185/200] md:aspect-[719/370] bg-cover bg-center flex items-center justify-center relative cursor-pointer group"
        >
          <div
            class="overlay w-full h-full absolute top-0 left-0 right-0 bottom-0 bg-gray-900 opacity-0 group-hover:opacity-50"
          ></div>

          <div
            class="text-white font-bold font-georgian text-lg md:text-[32px] leading-normal uppercase text-center z-20"
          >
            Family <br />Space
          </div>
        </div>
      </a>
      <a href="#commercial">
        <div
          class="bg-[url(@/assets/home/bg-commercial.webp)] aspect-[185/200] md:aspect-[719/370] bg-cover bg-center flex items-center justify-center relative cursor-pointer group"
        >
          <div
            class="overlay w-full h-full absolute top-0 left-0 right-0 bottom-0 bg-gray-900 opacity-0 group-hover:opacity-50"
          ></div>
          <div
            class="text-white font-bold font-georgian text-lg md:text-[32px] leading-normal uppercase text-center z-10"
          >
            Commercial
          </div>
        </div>
      </a>
      <a href="#putting-greens">
        <div
          class="bg-[url(@/assets/home/bg-putting-greens.webp)] aspect-[185/200] md:aspect-[719/370] bg-cover bg-center flex items-center justify-center relative cursor-pointer group"
        >
          <div
            class="overlay w-full h-full absolute top-0 left-0 right-0 bottom-0 bg-gray-900 opacity-0 group-hover:opacity-50"
          ></div>
          <div
            class="text-white font-bold font-georgian text-lg md:text-[32px] leading-normal uppercase text-center z-10"
          >
            Putting <br />Greens
          </div>
        </div>
      </a>
      <a href="#happy-pets">
        <div
          class="bg-[url(@/assets/home/bg-happy-pets.webp)] aspect-[185/200] md:aspect-[719/370] bg-cover bg-center flex items-center justify-center relative cursor-pointer group"
        >
          <div
            class="overlay w-full h-full absolute top-0 left-0 right-0 bottom-0 bg-gray-900 opacity-0 group-hover:opacity-50 transition-opacity"
          ></div>
          <div
            class="text-white font-bold font-georgian text-lg md:text-[32px] leading-normal uppercase text-center z-10"
          >
            Happy <br />Pets
          </div>
        </div>
      </a>
    </div>
  </div>
</template>
