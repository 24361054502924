<template>
  <DefaultTemplate v-if="currentTemplate === 'default'" />
  <DarkTemplate v-if="currentTemplate === 'dark'" />
  <BasicTemplate v-if="currentTemplate === 'basic'" />
</template>

<script lang="ts" setup>
import DefaultTemplate from "@/templates/default.vue";
import DarkTemplate from "@/templates/dark.vue";
import BasicTemplate from "@/templates/basic.vue";

import { getCurrentTemplate } from "./router/local-router";
import { onMounted, ref } from "vue";
const currentTemplate = ref(getCurrentTemplate());

const updateView = () => {
  currentTemplate.value = getCurrentTemplate();
};
onMounted(() => {
  window.addEventListener("pathchange", updateView);
});
</script>

<style>
/* FONT */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Noto+Sans+Georgian:wght@100..900&display=swap");

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
body {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  background-color: #fcfffa;
  color: #333;
  max-width: 100%;
  overflow-x: hidden;
}
</style>
