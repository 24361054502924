<template>
  <div class="w-full relative flex">
    <input
      v-bind="$attrs"
      readonly
      @focus="openDropdown"
      @blur="closeDropdown"
      class="border border-solid border-[#5e9822] py-3 px-5 text-sm  w-full bg-transparent outline-none"
      
    />
    <img
      src="@/assets/icons/down.svg"
      class="absolute right-5 top-4 transition-all"
      :class="{ 'rotate-180': isDropdownOpen }"
    />
    <ul
      v-show="isDropdownOpen"
      class="absolute top-12 left-0 right-0 z-50 w-full border border-solid border-[#5e9822]"
      :class="{
        'bg-white': mode === 'normal',
        'bg-[#333333]': mode === 'dark',
      }"
    >
      <li
        v-for="(option, index) in options"
        :key="index"
        @click="selectOption(option as string)"
        class="py-2 px-4 cursor-pointer hover:bg-gray-100 transition-all text-sm"
        :class="{
          'hover:bg-gray-100 text-primary-gray': mode === 'normal',
          'hover:bg-gray-500': mode === 'dark',
        }"
      >
        {{ option }}
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { ref, defineEmits, defineProps, computed } from "vue";

const props = defineProps({
  options: {
    type: Array,
    default: () => [],
  },
  mode: {
    type: String,
    default: "normal",
  },
});
const options = computed(() => {
  return props.options;
});
const emit = defineEmits(["input"]);
const isDropdownOpen = ref(false);
const openDropdown = () => {
  isDropdownOpen.value = true;
};
const closeDropdown = () => {
  setTimeout(() => {
    isDropdownOpen.value = false;
  }, 200);
};
const selectOption = (option: string) => {
  emit("input", option);
  closeDropdown();
};
</script>
