<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <NavHeaderLg v-if="!isMobile" />

  <NavHeaderSm v-else />
</template>
<script setup lang="ts">
import NavHeaderSm from "./header-sm.vue";
import NavHeaderLg from "./header-lg.vue";
import { onMounted, onUnmounted, ref } from "vue";

const isMobile = ref(false);

const checkDeviceType = () => {
  isMobile.value = window.matchMedia("(max-width: 768px)").matches;
};

onMounted(() => {
  checkDeviceType();
  window.addEventListener("resize", checkDeviceType);
});

onUnmounted(() => {
  window.removeEventListener("resize", checkDeviceType);
});
</script>
