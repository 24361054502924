<template>
  <div class="w-full p-7 bg-[url(@/assets/grass-vertical.webp)]">
    <div class="bg-[#f5faf0f5] p-5 flex flex-col gap-2.5">
      <div class="flex gap-2.5">
        <img
          src="@/assets/icons/check-light-green.svg"
          class="w-5 h-5 mt-1.5"
        />

        <span class="text-sm leading-loose text-primary-gray">
          <span class="text-light-green font-bold"> Our family friendly</span>
          residential artificial landscape grass products has a patent-pending
          <span class="text-light-green font-bold"> ProFlow</span>
          backing, which drains 2000 litres per hour. The days of slow draining
          artificial landscape grass through drainage holes and odour retaining
          landscape fabrics are a thing of the past.
        </span>
      </div>
      <div class="flex gap-2.5">
        <img
          src="@/assets/icons/check-light-green.svg"
          class="w-5 h-5 mt-1.5"
        />

        <span class="text-sm leading-loose text-primary-gray">
          <span class="text-light-green font-bold"> Supersoft™</span>
          fibre technology. This one-of-a-kind technology enhances the
          artificial grass blades' softness while maintaining their fibre
          strength and overall integrity.
        </span>
      </div>
      <div class="flex gap-2.5">
        <img
          src="@/assets/icons/check-light-green.svg"
          class="w-5 h-5 mt-1.5"
        />

        <span class="text-sm leading-loose text-primary-gray">
          Our landscape grass
          <span class="text-light-green font-bold"> 'Natural Real Series'</span>
          utilizes a mix of diamond and 'S' shaped blades, allowing the turf to
          stay upright while maintaining ProGreen's signature softness.
        </span>
      </div>
      <div class="flex gap-2.5">
        <img
          src="@/assets/icons/check-light-green.svg"
          class="w-5 h-5 mt-1.5"
        />

        <span class="text-sm leading-loose text-primary-gray">
          <span class="text-light-green font-bold"> Delustering</span>
          eliminates unnatural shine that artificial grass may have and is an
          extra effort that allows homeowners to enjoy a stunning,
          low-maintenance lawn for years to come.
        </span>
      </div>
      <div class="flex gap-6 justify-center items-center">
        <img src="@/assets/family-space/max-strength-badge.png" class="w-20"/>
        <img src="@/assets/family-space/15-year-tag.webp" class="w-24 h-auto"/>
      </div>
    </div>
  </div>
</template>
