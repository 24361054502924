<template>
  <div class="relative w-full">
    <div
      class="relative z-10 w-web-panel md:max-w-[95%] gap-4 flex p-10 bg-[#f5faf0] shadow-[8px_24px_34px_0_#a5a3a333] mx-auto"
    >
      <div class="max-w-[200px] flex flex-col gap-6 justify-center">
        <img src="@/assets/family-space/max-strength-badge.png" />
        <img src="@/assets/family-space/15-year-tag.webp" />
      </div>
      <div class="ml-auto max-w-[480px] flex flex-col gap-1">
        <div class="text-sm leading-loose text-primary-gray">
          <slot />
        </div>

        <div class="flex gap-2.5" v-for="point in bulletPoints" :key="point">
          <img
            src="@/assets/icons/check-light-green.svg"
            class="w-5 h-5 mt-1.5"
          />

          <div class="text-sm leading-loose text-primary-gray">
            {{ point }}
          </div>
        </div>
      </div>
    </div>
    <div class="absolute top-0 bottom-0 left-0 right-0 flex items-center z-0">
      <img src="@/assets/grass-wide.webp" class="w-full h-60" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, defineProps } from "vue";
const props = defineProps(["bulletPoints"]);
const bulletPoints = computed(() => {
  return props?.bulletPoints;
});
</script>
