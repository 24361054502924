<template>
  <div
    :class="{
      'border-green text-green hover:bg-green hover:text-white':
        color === 'green',
      'border-white text-white hover:bg-white hover:text-black':
        color !== 'green',
    }"
    class="border px-5 py-2 cursor-pointer transition-all text-center"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps } from "vue";

const props = defineProps({
  color: {
    type: String,
    default: "white",
  },
});
const color = computed(() => {
  return props.color;
});
</script>
