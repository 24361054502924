<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <NavHeader class="z-50 sticky top-0" />
  <component :is="currentView" class="mb-32" />
  <NavFooter />
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { getCurrentPage } from "@/router/local-router.ts";
import NavHeader from "@/components/navigation/NavigationHeader.vue";
import NavFooter from "@/components/navigation/nav-footer.vue";

const currentView = ref(getCurrentPage());

const updateView = () => {
  window.scrollTo(0, 0);
  currentView.value = getCurrentPage();
};

onMounted(() => {
  window.addEventListener("pathchange", updateView);
});
</script>
