<template>
  <div class="w-full p-7 bg-[url(@/assets/grass-vertical.webp)]">
    <div class="bg-[#f5faf0f5] p-5 flex flex-col gap-2.5">
      <slot />
      <div class="flex gap-6 justify-center items-center">
        <img src="@/assets/family-space/max-strength-badge.png" class="w-20" />
        <img src="@/assets/family-space/15-year-tag.webp" class="w-24 h-auto" />
      </div>
    </div>
  </div>
</template>
