<template>
  <div class="flex flex-col px-8 gap-5">
    <div
      class="font-bold font-georgian text-[22px] text-secondary-gray text-center"
    >
      About Us
    </div>
    <div class="flex flex-col gap-4 text-sm leading-loose text-secondary-gray">
      <div class="flex gap-3">
        <div>
          <strong>Perfect Green </strong>is an Official Pro-Green Dealer
          Servicing the Golden Horseshoe Region in Ontario.
        </div>
        <img src="@/assets/logo-light.svg" class="w-[120px]" />
      </div>
    </div>
    <div>
      We are a proud supplier and professional installer of Pro-Green Canada
      products. Whether you want us to complete your installation or simply
      supply you high quality turf, we are happy to give you a no obligation
      quote.
    </div>
    <div>
      We pride ourselves on customer satisfaction and all our landscape grass
      products come with a 15-year manufacturer’s warranty.
    </div>
    <div class="flex flex-col gap-4 text-sm leading-loose text-secondary-gray">
      <div class="flex">
        <div>
          Perfect Green offers a <strong>5-year warranty</strong> that that
          covers our installation. Our golf green products carry an 8-year
          warranty.
        </div>

        <img src="@/assets/family-space/max-strength-badge.png" class="w-32" />
      </div>
    </div>
  </div>
</template>
