import routes from "./routes-declaration.ts";

let currentPath = window.location.hash?.slice(1) || "";

window.addEventListener("hashchange", () => {
  onPathChange(window.location.hash);
});

const onPathChange = (pathHash: string) => {
  currentPath = pathHash.slice(1) || "";
  window.dispatchEvent(new CustomEvent("pathchange"));
};

const getCurrentPage = () => {
  const route = routes[currentPath] || routes["404"];
  return route.component;
};
const getCurrentPath = () => {
  const route = routes[currentPath] || routes["404"];
  return route.path;
};
const getCurrentTemplate = () => {
  const route = routes[currentPath] || routes["404"];
  return route.template || "default";
};

export { getCurrentPage, getCurrentPath, getCurrentTemplate };
