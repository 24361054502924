<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div
    class="flex flex-col mx-auto gap-y-16 md:gap-y-32 max-w-full overflow-x-hidden"
  >
    <!-- HERO -->
    <div
      class="flex flex-col gap-10 md:gap-4 lg:gap-10 md:flex-row items-center max-w-full md:max-w-[95%] lg:max-w-[1120px] mx-auto mt-10 md:mt-20 px-7 md:px-0"
    >
      <img src="@/assets/commerical/hero.webp" class="md:hidden" />
      <div class="md:max-w-[320px] lg:max-w-[360px] flex flex-col gap-6">
        <div
          class="font-bold text-[32px] md:text-[44px] lg:text-[52px] text-secondary-gray md:text-green leading-tight font-georgian"
        >
          The Perfect Commercial Space
        </div>
        <div class="text-sm leading-relaxed">
          <span class="text-light-green font-bold"
            >Stop wasting money and time on your frontage. Install our solution
            for a maintenance free, always perfect frontage for your
            business.</span
          >
          <br />
          <br />
          <span class="text-primary-gray">
            ProGreen Canada is Most Trusted Artificial Turf unlike turf
            manufactured overseas, which is often of poor quality and may
            contain lead or other heavy metals. Be confident that ProGreen
            Synthetic Turf Systems is
            <span class="text-light-green font-bold">100% lead </span> and
            <span class="text-light-green font-bold">heavy metal-free</span> and
            manufactured using the industry&apos;s highest quality standards.
            and and manufactured using the industry's highest quality standards.
          </span>
        </div>
      </div>
      <div class="ml-auto w-full max-w-[670px] hidden md:block">
        <img src="@/assets/commerical/hero.webp" />
      </div>
    </div>
    <GrassBgMobile class="md:hidden">
      <div class="text-sm">
        The uses for artificial grass in your business are endless, as are the
        benefits. We’ll steer you in the right direction of a product that
        perfectly fits your need. <br />Some of our commerical applications
        include:
      </div>
      <div class="flex gap-2.5" v-for="point in bulletPoints" :key="point">
        <img
          src="@/assets/icons/check-light-green.svg"
          class="w-5 h-5 mt-1.5"
        />

        <span class="text-sm leading-loose text-primary-gray">
          {{ point }}
        </span>
      </div>
    </GrassBgMobile>
    <!-- WIDE GRASS -->
    <div class="hidden md:block">
      <TaggedWidget :bullet-points="bulletPoints"
        >The uses for artificial grass in your business are endless, as are the
        benefits. We’ll steer you in the right direction of a product that
        perfectly fits your need. <br />Some of our commerical applications
        include:</TaggedWidget
      >
    </div>
    <!-- OUR WORK -->
    <div class="flex flex-col items-center gap-6 md:gap-10">
      <div
        class="md:uppercase font-bold font-georgian text-2xl md:text-[42px] text-green"
      >
        Our Work
      </div>
      <div class="grid grid-cols-2 md:grid-cols-4 gap-1">
        <img
          src="@/assets/commerical/c-1.webp"
          class="transition-all hover:scale-105 cursor-pointer hidden md:block"
        />
        <img
          src="@/assets/commerical/c-2.webp"
          
        />
        <img
          src="@/assets/commerical/c-3.webp"
          
        />
        <img
          src="@/assets/commerical/c-4.webp"
          
        />
        <img
          src="@/assets/commerical/c-5.webp"
          
        />
        <img
          src="@/assets/commerical/c-6.webp"
          
        />
        <img
          src="@/assets/commerical/c-7.webp"
          
        />
        <img
          src="@/assets/commerical/c-8.webp"
          class="transition-all hover:scale-105 cursor-pointer hidden md:block"
        />
      </div>
    </div>

    <!-- SOLUTIONS -->
    <div class="relative w-full max-w-full">
      <!-- GRASS STRIP BG -->
      <div
        class="absolute top-0 bottom-0 left-0 right-0 items-center z-0 hidden md:flex"
      >
        <img src="@/assets/grass-wide.webp" class="w-full h-60" />
      </div>
      <!-- SOLS -->
      <div class="flex flex-col items-center gap-10 z-10 relative max-w-full">
        <div
          class="text-2xl text-green md:text-[32px] leading-normal font-bold font-georgian text-center"
        >
          Our most popular <br />commerical space solutions
        </div>
        <div class="flex gap-10 flex-wrap items-center justify-center">
          <SolutionCard size="sm">
            <template #title>Natural Real Premium 60</template>
            <template #price>$7.50</template>
            Ideal for
            <span class="font-bold text-light-green"
              >medium to heavy trafﬁc</span
            >
            applications.<br />
            This turf utilizes a mix of “diamond” and “S” shaped blades 1.5"
            long that provides the ultimate softness that you can expect from
            ProGreen turf with improved resiliency.
            <template #height>1.125”</template>
            <template #weight>60 oz</template>
          </SolutionCard>
          <SolutionCard size="sm">
            <template #title>Natural Real Platinum 110</template>
            <template #price>$10.00</template>
            Our most luxurious product, "Natural Real Platinum 110" is more
            <span class="font-bold text-light-green"> lush and dense</span> than
            any artiﬁcial grass product in the industry. It creates a ﬂawless
            aesthetic with incredible durability, all while maintaining
            ProGreen’s signature softness.

            <template #height>0.5625”</template>
            <template #weight>110 oz</template>
          </SolutionCard>
        </div>
      </div>
      <!-- <FilledButton color="green" class="w-80 mx-auto mt-16 mb-20 z-20 relative"
        >See more turf options</FilledButton
      > -->
    </div>
  </div>
</template>
<script setup lang="ts">
import TaggedWidget from "@/components/common/tagged-wedget.vue";
import SolutionCard from "@/components/family-space/solution-card.vue";
// import FilledButton from "@/components/common/filled-button.vue";
import GrassBgMobile from "@/components/common/grass-bg-mobile.vue";
const bulletPoints = [
  "Pet boarding facilities and veterinarians",
  "Schools and playgrounds",
  "Athletic and recreational facilities",
  "Vehicle dealerships",
  "Apartments, condos, and other multifamily dwellings",
  "Developers and new construction",
  "Daycare facilities",
  "Restaurants, bars, and brewery common spaces",
  "Rooftops",
  "And much more…",
];
</script>
