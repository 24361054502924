<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <NavHeader class="z-50 sticky top-0" />
  <component :is="currentView" class="mb-16 md:mb-32" />
  <RequestQuoteForm />
  <NavFooter />
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from "vue";
import { getCurrentPage } from "@/router/local-router.ts";
import NavHeader from "@/components/navigation/NavigationHeader.vue";
import NavFooter from "@/components/navigation/nav-footer.vue";
import RequestQuoteForm from "@/components/common/request-quote-form.vue";
const currentView = ref(getCurrentPage());

const updateView = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
  currentView.value = getCurrentPage();
};

onMounted(() => {
  window.addEventListener("pathchange", updateView);
});
onUnmounted(() => {
  window.removeEventListener("pathchange", updateView);
});
</script>
