<template>
  <div class="flex flex-col gap-16 mb-20 pt-8 md:mt-0">
    <div class="mx-auto max-w-[433px]">
      <div
        class="font-georgia text-2xl md:text-3xl font-bold text-center text-green"
      >
        Choose the highest quality turf for your yard!
      </div>
    </div>
    <div class="w-web-panel mx-auto relative max-w-[95%]">
      <div
        class="z-0 bg-[url(@/assets/home/garden.webp)] h-[560px] bg-contain flex items-center justify-center w-[440px]"
      >
        <div class="text-[40px] font-bold text-white max-w-96">
          What sets ProGreen apart from the Competition?
        </div>
      </div>

      <div
        class="absolute -bottom-24 right-0 shadow-lg w-[440px] z-10 bg-white px-12 pt-3 pb-10"
      >
        <img src="@/assets/home/grass-role.webp" class="mx-auto w-60" />
        <div class="flex flex-col gap-6">
          <checked-bullet v-for="bullet in bulletPoints" :key="bullet">
            {{ bullet }}
          </checked-bullet>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import CheckedBullet from "@/components/common/checked-bullet.vue";

const bulletPoints = [
  "15 Year Manufacturer's Warranty",
  "100% made in North America",
  "Workmanship Warranty",
  "ProFlow™ Backing",
  "Diamond Shaped Blades",
  "Cool Fiber Technology",
  "Highest Quality Resins From Dow-Dupont",
];
</script>
