<template>
  <div class="relative w-full">
    <div
      class="relative z-10 w-web-panel md:max-w-[95%] flex p-10 bg-[#f5faf0] shadow-[8px_24px_34px_0_#a5a3a333] mx-auto"
    >
      <div class="max-w-[200px] flex flex-col gap-6 justify-center">
        <img src="@/assets/family-space/max-strength-badge.png" />
        <img src="@/assets/family-space/15-year-tag.webp" />
      </div>
      <div class="ml-auto max-w-[480px]">
        <div class="flex flex-col gap-1">
          <div class="text-sm leading-loose text-primary-gray">
            Superior drainage, unmatched durability, no harsh chemicals or heavy
            metals and the best warranties in the business, that’s why
            <span class="'font-bold text-light-green'">Design Turf</span> is the
            clear choice for residential artificial landscape grass applications
            All of our innovative artificial landscape grass products are
            entirely free of lead, heavy metals and 100% safe for your family.
          </div>
          <div class="flex gap-2.5">
            <img
              src="@/assets/icons/check-light-green.svg"
              class="w-5 h-5 mt-1.5"
            />

            <span class="text-sm leading-loose text-primary-gray">
              <span class="text-light-green font-bold">
                Our family friendly</span
              >
              residential artificial landscape grass products has a
              patent-pending
              <span class="text-light-green font-bold"> ProFlow</span>
              backing, which drains 2000 litres per hour. The days of slow
              draining artificial landscape grass through drainage holes and
              odour retaining landscape fabrics are a thing of the past.
            </span>
          </div>
          <div class="flex gap-2.5">
            <img
              src="@/assets/icons/check-light-green.svg"
              class="w-5 h-5 mt-1.5"
            />

            <span class="text-sm leading-loose text-primary-gray">
              <span class="text-light-green font-bold"> Supersoft™</span>
              fibre technology. This one-of-a-kind technology enhances the
              artificial grass blades' softness while maintaining their fibre
              strength and overall integrity.
            </span>
          </div>
          <div class="flex gap-2.5">
            <img
              src="@/assets/icons/check-light-green.svg"
              class="w-5 h-5 mt-1.5"
            />

            <span class="text-sm leading-loose text-primary-gray">
              Our landscape grass
              <span class="text-light-green font-bold">
                'Natural Real Series'</span
              >
              utilizes a mix of diamond and 'S' shaped blades, allowing the turf
              to stay upright while maintaining ProGreen's signature softness.
            </span>
          </div>
          <div class="flex gap-2.5">
            <img
              src="@/assets/icons/check-light-green.svg"
              class="w-5 h-5 mt-1.5"
            />

            <span class="text-sm leading-loose text-primary-gray">
              <span class="text-light-green font-bold"> Delustering</span>
              eliminates unnatural shine that artificial grass may have and is
              an extra effort that allows homeowners to enjoy a stunning,
              low-maintenance lawn for years to come.
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="absolute top-0 bottom-0 left-0 right-0 flex items-center z-0">
      <img src="@/assets/grass-wide.webp" class="w-full h-60" />
    </div>
  </div>
</template>
