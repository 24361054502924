<template>
  <div
    class="w-full max-w-full p-8 bg-[url(@/assets/bg-menu.webp)] bg-cover text-white"
  >
    <div class="flex flex-col gap-6">
      <div class="flex flex-col gap-5">
        <a
          v-for="item in footerItems.services"
          :key="item.title"
          :href="item.link"
          class="text-base font-medium text-center leading-[1]"
        >
          {{ item.title }}
        </a>
        <div class="w-16 h-0.5 bg-light-green mx-auto"></div>
        <div
          v-for="item in footerItems.locations"
          :key="item"
          class="text-base font-medium text-center leading-[1]"
        >
          {{ item }}
        </div>
        <div class="w-16 h-0.5 bg-light-green mx-auto"></div>
        <div class="flex gap-5">
          <img src="@/assets/logo-dark.svg" class="w-24" />
          <div class="flex flex-col gap-3">
            <div
              class="text-base leading-normal"
              v-for="item in footerItems.contact"
              :key="item"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div class="w-16 h-0.5 bg-light-green mx-auto"></div>
        <div class="text-xs leading-relaxed text-center">
          Property of 11x12 Solutions Inc.<br />
          All rights reserved.
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
const footerItems = {
  services: [
    { title: "Family Space", link: "#family-space" },
    { title: "Commercial", link: "#commercial" },
    { title: "Putting Greens", link: "#putting-greens" },
    { title: "Happy Pets", link: "#happy-pets" },
    { title: "Landscape Lightings", link: "#lighting" },
  ],
  locations: ["Hamilton", "Burlington", "St. Catharines", "Niagra"],
  contact: [
    "info@perfectgreen.ca",
    "+1 (905) 674-5983",
    "",
  ],
};
</script>
