<template>
  <div
    class="sticky top-0 z-20 w-full h-[72px] bg-primary-gray text-white flex items-center justify-center px-4"
  >
    <div class="max-w-[1120px] w-full flex md:gap-4 lg:gap-6 items-center h-full">
      <a href="#">
        <img
          src="@/assets/logo-dark.svg"
          alt="logo"
          class="-mt-2 cursor-pointer hover:scale-110 transition-all"
        />
      </a>

      <div class="flex items-center gap-3 lg:gap-5 h-full">
        <nav-item
          href="#family-space"
          class="border-b-[6px] pt-1.5"
          :class="{
            'border-light-green': currentPath === 'family-space',
            'border-transparent': currentPath !== 'family-space',
          }"
          >Family Space</nav-item
        >
        <nav-item
          href="#commercial"
          class="border-b-[6px] pt-1.5"
          :class="{
            'border-light-green': currentPath === 'commercial',
            ' border-transparent': currentPath !== 'commercial',
          }"
          >Commerical</nav-item
        >
        <nav-item
          href="#putting-greens"
          class="border-b-[6px] pt-1.5"
          :class="{
            'border-light-green': currentPath === 'putting-greens',
            'border-transparent': currentPath !== 'putting-greens',
          }"
          >Putting Greens</nav-item
        >
        <nav-item
          href="#happy-pets"
          class="border-b-[6px] pt-1.5"
          :class="{
            'border-light-green': currentPath === 'happy-pets',
            'border-transparent': currentPath !== 'happy-pets',
          }"
          >Happy Pets</nav-item
        >
        <nav-item
          href="#lighting"
          class="border-b-[6px] pt-1.5"
          :class="{
            'border-light-green': currentPath === 'lighting',
            'border-transparent': currentPath !== 'lighting',
          }"
          >Lighting</nav-item
        >
      </div>
      <default-button class="ml-auto transition-all md:px-2" @click="onReuestQuote">{{
        buttonText
      }}</default-button>
    </div>
  </div>
</template>
<script setup lang="ts">
import DefaultButton from "@/components/common/default-button.vue";
import NavItem from "@/components/navigation/nav-item.vue";
import { getCurrentPath } from "@/router/local-router";
import { computed, onMounted, onUnmounted, ref } from "vue";
const scrollPos = ref(window.scrollY);

const handleScroll = () => {
  scrollPos.value = window.scrollY;
};

onUnmounted(() => {
  if (window) {
    window.removeEventListener("scroll", handleScroll);
  }
});
const buttonText = computed(() => {
  if (scrollPos.value < 700) {
    return "Request a Quote";
  } else {
    return "Contact Us";
  }
});
const currentPath = ref(getCurrentPath());
const updatePath = () => {
  currentPath.value = getCurrentPath();
};

onMounted(() => {
  if (window) {
    window.addEventListener("scroll", handleScroll);
  }
  window.addEventListener("pathchange", updatePath);
});
const onReuestQuote = () => {
  document
    .getElementById("quote-div")
    ?.scrollIntoView({ behavior: "smooth", block: "center" });
};
</script>
