<template>
  <!-- REQUEST A QUOTE -->
  <div
    class="w-web-panel mx-auto relative md:mb-64 max-w-full md:max-w-[95%]"
    id="quote-div"
  >
    <div class="md:flex max-w-[440px] relative hidden">
      <img
        src="@/assets/home/request-quote.webp"
        class="w-full"
        v-if="mode === 'normal'"
      />
      <img
        src="@/assets/home/request-quote-dark.webp"
        class="w-full"
        v-if="mode === 'dark'"
      />
    </div>
    <div
      class="md:hidden bg-[url(@/assets/request-quote-mobile.webp)] w-full aspect-[375/480] bg-cover flex flex-col px-7 py-9 max-w-full"
      :class="{
        'bg-[url(@/assets/request-quote-mobile.webp)]': mode === 'normal',
        'bg-[url(@/assets/home/request-quote-dark.webp)]': mode === 'dark',
      }"
    >
      <div class="flex flex-col-reverse text-white gap-10 mt-auto">
        <div class="flex flex-col gap-5">
          <div class="flex gap-3">
            <img src="@/assets/icons/email_white.svg" class="w-5 h-5" />
            <div class="text-sm">info@perfectgreen.ca</div>
          </div>
          <div class="flex gap-3">
            <img src="@/assets/icons/location_white.svg" class="w-5 h-5" />
            <div class="text-sm"></div>
          </div>
        </div>

        <div class="font-bold font-georgian text-[24px]">
          Call or text us at<br />+1 (905) 674-5983
        </div>
      </div>
    </div>
    <div
      class="w-full md:w-[440px] md:absolute top-24 right-0"
      :class="{
        'bg-white sm:shadow-[8px_24px_34px_0_#a5a3a333]': mode === 'normal',
        'bg-[#333333]': mode === 'dark',
      }"
    >
      <div
        class="p-7 md:p-10 flex flex-col gap-8"
        :class="{
          'text-white': mode === 'dark',
          'text-green': mode === 'normal',
        }"
      >
        <div class="text-[24px] font-bold leading-normal hidden md:block">
          Call or text us at
          <br />
          +1 (905) 674-5983
        </div>
        <div
          class="flex-col gap-2.5 hidden md:flex"
          :class="{
            'text-white': mode === 'dark',
            'text-[#333333]': mode === 'normal',
          }"
        >
          <div
            class="flex gap-2.5"
            :class="{
              'text-white': mode === 'dark',
              'text-green': mode === 'normal',
            }"
          >
            <img src="@/assets/icons/email_white.svg" v-if="mode === 'dark'" />
            <img src="@/assets/icons/email.svg" v-if="mode === 'normal'" />
            <div class="text-sm">info@perfectgreen.ca</div>
          </div>
          <!-- <div
            class="flex gap-2.5"
            :class="{
              'text-white': mode === 'dark',
              'text-green': mode === 'normal',
            }"
          >
            <img src="@/assets/icons/location.svg" v-if="mode === 'normal'" />
            <img
              src="@/assets/icons/location_white.svg"
              v-if="mode === 'dark'"
            />
            <div class="text-sm">
              1 Hunter Street East, Hamilton, ON L8N 3W1
            </div>
          </div> -->
        </div>
        <div class="flex gap-4 md:hidden">
          <img src="@/assets/icons/phone.svg" class="h-16 w-16" />
          <div
            class="text-[22px] font-bold font-georgian leading-normal"
            :class="{
              'text-white': mode === 'dark',
              'text-secondary-gray': mode === 'normal',
            }"
          >
            Or enter your info, and we’ll call you
          </div>
        </div>
        <div class="flex flex-col gap-2.5">
          <div
            class="text-primary-gray text-sm hidden md:block"
            :class="{
              'text-white': mode === 'dark',
              'text-secondary-gray': mode === 'normal',
            }"
          >
            Or enter your info, and we’ll call you
          </div>
          <DropDown
            :mode="mode"
            placeholder="Service you're interested in"
            :options="dropDownOptions"
            :value="formData.service"
            @input="onServiceSelected"
          />
          <DefaultInput
            placeholder="Your name"
            type="name"
            :mode="mode"
            @input="(e) => onInput('name', e.target.value)"
          />
          <DefaultInput
            placeholder="Your email"
            type="email"
            :mode="mode"
            @input="(e) => onInput('email', e.target.value)"
          />
          <DefaultInput
            type="phone"
            placeholder="Your phone number"
            :mode="mode"
            @input="(e) => onInput('phone', e.target.value)"
          />
        </div>
        <FilledButton @click="onSubmit" color="green">Submit</FilledButton>
        <div
          class="text-[12px] font-normal font-georgian leading-normal italic"
          :class="{
            'text-white': mode === 'dark',
            'text-secondary-gray': mode === 'normal',
          }"
        >
          * We take customer privacy seriously and do
          not sell or give out any customer information. We do not keep a
          mailing list nor distribute a newsletter.
        </div>
      </div>
    </div>
  </div>
  <Alert v-if="validation.hasError">{{ validation.message }}</Alert>
</template>
<script setup lang="ts">
import DropDown from "@/components/common/form/drop-down.vue";
import DefaultInput from "@/components/common//form/default-input.vue";
import FilledButton from "./filled-button.vue";
import Alert from "@/components/common/alert.vue";
import { computed, defineProps, nextTick, ref } from "vue";

const props = defineProps(["mode"]);
const formData = ref({
  service: "",
  name: "",
  email: "",
  phone: "",
});
const validation = ref({
  hasError: false,
  message: "",
});
const mode = computed(() => {
  return props.mode || "normal";
});

const dropDownOptions = [
  "Artificial Grass - No pet",
  "Artificial Grass - Pet Friendly",
  "Putting Green",
];
const validateForm = () => {
  if (formData.value.service === "") {
    validation.value.hasError = true;
    validation.value.message =
      "Please select the service you are interested in.";
  } else if (formData.value.name === "") {
    validation.value.hasError = true;
    validation.value.message = "Please enter your name.";
  } else if (formData.value.email === "") {
    validation.value.hasError = true;
    validation.value.message = "Please enter your email address.";
  } else if (formData.value.phone === "") {
    validation.value.hasError = true;
    validation.value.message = "Please enter your phone number.";
  } else if (!checkEmail(formData.value.email)) {
    validation.value.hasError = true;
    validation.value.message = "Please enter correct email address.";
  }
  // else if (!isPhoneNumberValid(formData.value.phone)) {
  //   validation.value.hasError = true;
  //   validation.value.message = "Please enter correct phone number.";
  // }
};
const onServiceSelected = (selectedOption: string) => {
  onInput("service", selectedOption);
};
const onInput = (key: keyof typeof formData.value, val: string) => {
  formData.value[key] = val;
};
const checkEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const onSubmit = async () => {
  validation.value.hasError = false;
  await nextTick();
  validateForm();
  if (!validation.value.hasError) {
    sendFormData();
  }
};
const sendFormData = async () => {
  const url = "https://api.amirayachts.com/perfect-green";
  // const urlDev = "http://localhost:3000/perfect-green";
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData.value),
    });

    if (response.ok) {
      location.href = "/#thankyou";
    } else {
      validation.value = {
        hasError: true,
        message: "Something went wrong Please try again.",
      };
    }
  } catch (error) {
    validation.value = {
      hasError: true,
      message: "Something went wrong Please try again.",
    };
  }
};
</script>
