<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <Transition name="slide-fade">
    <div
      v-if="showInfo"
      class="fixed top-20 right-0 left-0 md:left-[unset] md:right-6 lg:right-8 md:rounded-lg border border-gray-300 px-6 py-4 text-sm max-w-xs bg-white text-gray-800 shadow-lg transform transition-transform duration-200 ease-in-out"
      style="z-index: 1000"
    >
      <div class="flex items-start">
        <div class="flex-shrink-0">
          <svg
            class="w-6 h-6 text-light-green"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M18 10c0 4.418-3.582 8-8 8s-8-3.582-8-8 3.582-8 8-8 8 3.582 8 8zm-8-3a1 1 0 00-1 1v2a1 1 0 002 0V8a1 1 0 00-1-1zm0 8a1 1 0 100-2 1 1 0 000 2z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
        <div class="ml-3 flex-1">
          <slot />
        </div>
        <div class="ml-4 flex-shrink-0 flex cursor-pointer hover:scale-110 transition-all" @click="showInfo = false">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-5 h-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M10 8.586L4.707 3.293a1 1 0 00-1.414 1.414L8.586 10l-5.293 5.293a1 1 0 001.414 1.414L10 11.414l5.293 5.293a1 1 0 001.414-1.414L11.414 10l5.293-5.293a1 1 0 00-1.414-1.414L10 8.586z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
const showInfo = ref(false);

onMounted(() => {
  setTimeout(() => {
    showInfo.value = true;
  }, 100);
  setTimeout(() => {
    showInfo.value = false;
  }, 3000);
});
</script>
<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(90px);
  opacity: 0;
}
</style>
