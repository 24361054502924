<template>
  <div class="w-full">
    <div
      class="bg-[url(@/assets/grass-wide.webp)] h-[358.1px] bg-contain hidden md:block"
    ></div>
    <div
      class="w-web-panel p-12 mx-auto md:-mt-60 bg-white shadow-xl max-w-[95%]"
    >
      <div class="grid grid-cols-3 gap-16">
        <div class="">
          <div class="uppercase font-bold text-[36px] text-green">About Us</div>
          <img src="@/assets/logo-light.svg" class="w-60" />
        </div>
        <div class="col-span-2 flex flex-col gap-5 text-sm leading-loose">
          <div>
            <span class="font-bold text-green">Perfect Green</span> is an
            Official Pro-Green Dealer Servicing the Golden Horseshoe Region in
            Ontario. We are a proud supplier and professional installer of
            Pro-Green Canada products.
          </div>
          <div>
            Whether you want us to complete your installation or simply supply
            you high quality turf, we are happy to give you a no obligation
            quote.
          </div>
          <div>
            We pride ourselves on customer satisfaction and all our landscape
            grass products come with a 15-year manufacturer’s warranty. Perfect
            Green offers a
            <span class="font-bold text-green">5-year warranty</span> that that
            covers our installation. Our golf green products carry an 8-year
            warranty.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
