<template>
  <div class="flex flex-col gap-8 md:gap-40 mx-auto">
    <div
      class="flex flex-col gap-16 relative w-full md:aspect-[20/9] max-h-screen md:-mt-16 mx-auto px-8 lg:px-40 pt-20 md:pt-0 text-center md:text-left bg-[url(@/assets/home/hero-mobile.webp)] md:bg-[url(@/assets/hero/hero-home.webp)] bg-cover"
    >
      <!-- HEADING -->
      <div class="flex flex-col justify-center md:h-full w-full max-w-[1120px]">
        <div
          class="text-white text-[32px] md: text-4xl lg:text-5xl font-bold max-w-[460px] lg:max-w-[660px] leading-[1.35] font-georgian"
        >
          The Perfect Green space for you and your family
        </div>
      </div>
      <!-- ICON TEXT -->
      <div
        class="flex flex-col gap-4 md:gap-2.5 items-center justify-center md:block md:absolute md:bottom-10"
      >
        <img
          src="@/assets/pro-green-logo.webp"
          class="h-10 w-max block md:hidden"
        />
        <div class="text-white text-sm">
          Official Pro-Green Dealer<br />
          Servicing the Golden Horseshoe Region
        </div>
        <img
          src="@/assets/pro-green-logo.webp"
          class="h-8 w-max hidden md:block"
        />
      </div>
      <!-- BUTTON MOBILE ONLY -->
      <div class="md:hidden w-full mt-auto mb-16">
        <default-button @click="scrollToForm">Request a Quote</default-button>
      </div>
    </div>
    <!-- SECTION WELCOME -->
    <div
      class="flex flex-col gap-x-2 md:gap-x-10 gap-y-6 md:gap-y-16 px-8 lg:px-0"
    >
      <div class="w-web-panel max-w-full mx-auto grid grid-cols-3 gap-10">
        <div class="col-span-3 md:col-span-2 flex flex-col gap-6">
          <div class="flex md:hidden items-center">
            <img
              src="@/assets/home/welcome-to-text-mobile.webp"
              class="max-w-48 h-min w-full"
            />
            <div class="ml-auto max-w-36 w-full">
              <img src="@/assets/home/welcome-img.webp" />
            </div>
          </div>
          <img
            src="@/assets/home/welcome-to-text.webp"
            class="hidden md:block"
          />

          <div class="text-sm md:text-base">
            Are you tired of spending hours maintaining your natural grass lawn?
            Are you looking for a durable, low-maintenance alternative that will
            stay green and beautiful all year round? Look no further than
            <span class="font-bold text-green">ProGreen Artificial Turf! </span>
          </div>
          <div class="text-sm md:text-base">
            ProGreen grass is carefully manufactured to look and feel
            <span class="font-bold text-green">like real grass</span>, without
            the hassle of watering, mowing, and fertilizing. It's perfect for
            residential and commercial properties, and can be used in a variety
            of applications such as lawns, playgrounds, sports fields, and more.
          </div>
        </div>
        <div class="hidden md:block">
          <img src="@/assets/home/welcome-img.webp" />
        </div>
      </div>
      <div class="w-web-panel max-w-full mx-auto grid grid-cols-3 gap-10">
        <div class="col-span-3 md:hidden">
          <img src="@/assets/home/grass.webp" class="max-w-52 mx-auto" />
        </div>
        <div class="hidden md:block">
          <img src="@/assets/home/grass.webp" />
        </div>
        <div class="col-span-3 md:col-span-2 flex flex-col gap-6">
          <div class="text-sm md:text-base">
            But what sets ProGreen apart from the competition? Our turf comes
            with a <span class="font-bold text-green">15-year warranty</span>,
            giving you peace of mind and ensuring that your investment will last
            for years to come. Plus, our turf is made with the
            <span class="font-bold text-green"
              >highest quality recycled materials</span
            >, making it durable and resistant to fading and wear. ProGreen
            Artificial Turf!
          </div>
          <div class="">
            <div class="text-green text-lg md:text-3xl font-bold">
              So why wait?
            </div>
            <div class="text-sm md:text-base">
              Make the switch to ProGreen Artificial Turf today and enjoy a
              beautiful, hassle-free lawn for years to come. Contact us to learn
              more and to get started on your project.
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- MOBILE ITEMS -->
    <ImgsGrid class="md:hidden" />
    <hq-turf-mobile class="md:hidden" />
    <PerfectLights class="md:hidden" />
    <AboutUsMobile class="md:hidden" />
    <!-- DESKTOP -->
    <AboutUs class="hidden md:block" />
    <hq-turf class="hidden md:flex" />
    <ImgsGrid class="hidden md:block" />
    <PerfectLights class="hidden md:block" />
  </div>
</template>
<script setup lang="ts">
import DefaultButton from "@/components/common/default-button.vue";
import ImgsGrid from "@/components/home/imgs-grid.vue";
import AboutUs from "@/components/home/about-us.vue";
import AboutUsMobile from "@/components/home/about-us-mobile.vue";
import HqTurf from "@/components/home/hq-turf.vue";
import HqTurfMobile from "@/components/home/hq-turf-mobile.vue";
import PerfectLights from "@/components/home/perfect-lights.vue";

const scrollToForm = () => {
  document
    .getElementById("quote-div")
    ?.scrollIntoView({ behavior: "smooth", block: "center" });
};
</script>
